@font-face {
  font-family: 'Grotesque';
  font-style: normal;
  font-weight: 400;
  src: local('Grotesque'),
    url('./assets/fonts/DarkerGrotesque/DarkerGrotesque-Regular.ttf');
}

@font-face {
  font-family: 'IBMPlexSans';
  font-style: normal;
  font-weight: 400;
  src: local('IBMPlexSans'), url('./assets/fonts/IBM/IBMPlexSans-Regular.ttf');
}

@font-face {
  font-family: 'Grotesque';
  font-style: normal;
  font-weight: bold;
  src: local('Grotesque'),
    url('./assets/fonts/DarkerGrotesque/DarkerGrotesque-Bold.ttf');
}

@font-face {
  font-family: 'IBMPlexSans';
  font-style: normal;
  font-weight: 600;
  src: local('IBMPlexSans'), url('./assets/fonts/IBM/IBMPlexSans-SemiBold.ttf');
}

@font-face {
  font-family: 'Roboto Mono';
  src: local('Roboto Mono'),
    url('./assets/fonts/Roboto_Mono/RobotoMono-VariableFont_wght.ttf')
      format('truetype');
}

@font-face {
  font-family: 'Inter';
  src: url('./assets/fonts/Inter-VariableFont_slnt_wght.ttf');
}

@font-face {
  font-family: 'Rubik';
  src: url('./assets/fonts/Rubik-VariableFont_wght.ttf');
}

*,
*::after,
*::before {
  margin: 0;
  padding: 0;
  box-sizing: inherit;
}

html,
body,
#root {
  height: 100%;
}

html {
  box-sizing: border-box;
  font-size: 62.5%;
}

body {
  margin: 0;
  font-family: 'IBMPlexSans';
  font-weight: 400;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

h1,
h2,
h3 {
  font-family: 'Grotesque';
  font-weight: bold;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@media (max-width: 1400px) {
  html {
    font-size: 56.25%;
  }
}

@media (max-width: 768px) {
  html {
    font-size: 50%;
  }
}

@media (min-width: 1600px) {
  html {
    font-size: 75%;
  }
}
